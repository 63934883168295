import React from "react";
import { Link } from "gatsby";

const HamburgerMenu = () => (
  <div className="hamburgerMenu">
    <label>
      <input type="checkbox" className="burgerCheckbox" />

      <ul className="hamburgerMenuList">
        <Link className="headerLink" to="/#product">
          <li>
            <h4>Product</h4>
          </li>
        </Link>
        <Link className="headerLink" to="/competitive_intelligence">
          <li>
            <i className="fa fa-random fa-fw headerIcon" />
            Competitive Intelligence
          </li>
        </Link>
        <Link className="headerLink" to="/flow_forecasting">
          <li>
            <i className="fa fa-chart-line fa-fw headerIcon" />
            Flow Forecasting & Attribution
          </li>
        </Link>
        <Link className="headerLink" to="/fee_optimization">
          <li>
            <i className="fa fa-search-dollar fa-fw headerIcon" />
            Fee Optimization
          </li>
        </Link>
        <Link className="headerLink" to="/investor_preferences">
          <li>
            <i className="fa fa-heartbeat fa-fw headerIcon" />
            Investor Preferences
          </li>
        </Link>
        <Link className="headerLink" to="/research/#top">
          <li>
            <h4>Research</h4>
          </li>
        </Link>
        <Link className="headerLink" to="/about/#top">
          <li>
            <h4>Company</h4>
          </li>
        </Link>
        <Link className="headerLink" to="/about">
          <li>
            <i className="fa fa-fw" />
            About Us
          </li>
        </Link>
        <Link className="headerLink" to="/news">
          <li>
            <i className="fa fa-fw" />
            Flowspring in the News
          </li>
        </Link>
        <Link className="headerLink" to="/careers">
          <li>
            <i className="fa fa-fw" />
            Careers
          </li>
        </Link>
        <a
          className="headerLink"
          href="https://www.issgovernance.com/mi-universal-form/?aoi=Flowspring&country=United%20States"
        >
          <li>
            <i className="fa fa-fw" />
            Contact Us
          </li>
        </a>
        <a
          className="headerLink"
          href="https://app.flowspring.com/accounts/signup"
        >
          <li>
            <h4>Free Trial</h4>
          </li>
        </a>
        <a className="headerLink" href="https://app.flowspring.com">
          <li>
            <h4>Sign In</h4>
          </li>
        </a>
      </ul>
      <i className="fa fa-bars fa-fw fa-2x" />
    </label>
  </div>
);

export default HamburgerMenu;
