import React from "react";
import { Link } from "gatsby";
import logo from "../images/Flowspring Logo Reverse_ISS MI.png";
import HamburgerMenu from "./HamburgerMenu";
import "../styles/_header.scss";

const Header = () => (
  <nav className="pageHeader">
    <div className="headerContainer">
      <div className="logo">
        <Link to="/#highlight" style={{ height: 0 }}>
          <img alt="Flowspring an ISS MI Business" src={logo} />
        </Link>
      </div>

      <div className="horizontalMenu">
        <ul>
          <li className="headerItem">
            <Link to="/#product">Product</Link>
            <i className="fa fa-chevron-down headerChevron" />
            <div className="dropdownContent">
              <h4>Flowspring Features</h4>
              <Link className="headerLink" to="/competitive_intelligence">
                <i className="fa fa-random fa-fw headerIcon" />
                Competitive Intelligence
              </Link>
              <Link className="headerLink" to="/flow_forecasting">
                <i className="fa fa-chart-line fa-fw headerIcon" />
                Flow Forecasting & Attribution
              </Link>
              <Link className="headerLink" to="/fee_optimization">
                <i className="fa fa-search-dollar fa-fw headerIcon" />
                Fee Optimization
              </Link>
              <Link className="headerLink" to="/investor_preferences">
                <i className="fa fa-heartbeat fa-fw headerIcon" />
                Investor Preferences
              </Link>
            </div>
          </li>
          <Link to="/research/#top">
            <li>Research</li>
          </Link>
          <Link to="/about/#top">
            <li className="headerItem">
              Company
              <i className="fa fa-chevron-down headerChevron" />
              <div className="dropdownContent">
                <Link className="headerLink" to="/about">
                  About Us
                </Link>
                <Link className="headerLink" to="/news">
                  Flowspring in the News
                </Link>
              </div>
            </li>
          </Link>
          <a href="https://www.issgovernance.com/mi-universal-form/?aoi=Flowspring&country=United%20States">
            <li className="headerItem">Contact</li>
          </a>
          <a
            className="standout"
            href="https://app.flowspring.com/accounts/signup"
          >
            <li className="headerItem">Free Trial</li>
          </a>
          <a href="https://app.flowspring.com">
            <li className="headerItem">
              Sign In
              <i className="fa fa-chevron-right headerChevron" />
            </li>
          </a>
        </ul>
      </div>
      <HamburgerMenu />
    </div>
  </nav>
);

export default Header;
