import React from "react";
import issmilogo from "../images/iss_mi_logo_white.png";
import "../styles/_footer.scss";

const Footer = () => (
  <footer>
    <div id="mainFooter">
      <div style={{ width: 125 }}>
        <a href="https://www.issgovernance.com/market-intelligence/" target="_blank" rel="noreferrer"><img src={issmilogo} /></a>
      </div>
      <div style={{ flexGrow: 1 }}>
        <div style={{ textAlign: "center", width: "100%" }}>
          Copyright © 2022 ISS Market Intelligence. All Rights Reserved.
          <br />
          No Reproduction Without Prior Authorizations.
        </div>
      </div>

    </div>
  </footer>
);

export default Footer;
